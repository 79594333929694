import React from "react";
import styled from "styled-components";
import Layout from '../components/layout';
import { graphql } from "gatsby";
import Wrapper from '../components/layouts/standard-wrapper';
import { BackButton } from "../components/buttons/buttons";
import {Trans} from 'gatsby-plugin-react-i18next';

const Container = styled.div`
    h1{
        padding:2rem 0 1rem 0;
    }
    .error-row{
        padding:2rem 0;
        text-align:center;
    }
`
const Missing = () => {

    return (
        <Layout>
            <Wrapper>
                <Container>
                    <BackButton/>
                    <div className="error-row">
                        <h1><Trans i18nKey="404.h1"/></h1>
                        <p><Trans i18nKey="404.description" components={{cta: <a href="mailto:contactsupport@asterra.io" className="text-link">Contact us.</a>}}/></p>
                    </div>                              
                </Container>
            </Wrapper>
        </Layout>
    )
};
export default Missing;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common", "404", "navigation"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;